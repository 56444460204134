import React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
// import serviceCommunity from '../../../lib/service/ServiceCommunity';
import Layout from '../components/Layout/LayoutIndex';
import { getHtmlTDK, ishaizhan } from '../../../lib/utils/UtilsCommon';
import CompanyIntro from './components/companyIntro';
import DevelopmentHistory from './components/developmentHistory';
import StudentStory from './components/studentStory';
import NewsInformation from './components/newsInformation';
import HonoraryAwards from './components/honoraryAwards';
import HomeTrainingTopBanner from './components/homeTrainingTopBanner';
import CategoryList from './components/categoryList';
import ProductAdvantages from './components/productAdvantages';
import CommunityList from './components/communityList';
import FamousTeacher from './components/famousTeacher';
import { post } from '../../../lib/utils/Request';
import BrandInfo from './components/brandInfo';
import HonoraryPan from './components/honoraryPan';
import BrandReport from './components/brandReport';
import FixedRightTools from '../components/fixed-right-tools';

import './style/HomeTraining.less';

/**
 * state
 */
interface IState {
	htmlTDK: {
		title: string;
	};
}

/**
 * HomeTraining page
 *
 * @class HomeTraining
 * @extends {React.Component<any>}
 */
@autobind
class HomeTraining extends React.Component<any, any> {
	constructor(props: any, context?: any) {
		super(props, context);

		/**
		 * 静态化渲染数据放这里处理
		 */

		const state: any = {
			htmlTDK: {
				title: '学兴趣就上兴趣岛——名师更多，课程更全',
				pageDescription: '兴趣岛兴趣学堂-名师更多，课程更全。摄影、瑜伽等30门主流课程21门学员数量全国领先。',
				pageKeyword: '兴趣,兴趣岛,兴趣岛兴趣学堂,兴趣学堂,千聊兴趣岛,兴趣岛-名师更多，课程更全,兴趣岛兴趣学堂-名师更多，课程更全,千聊,知识付费,内容付费,千聊特训营,成人教育',
			},
			qlIntroIndex: 0,
		};

		this.state = state;
	}

	/**
	 * 动态渲染数据放这里处理
	 *
	 * @static
	 * @param {*} { req }
	 * @returns {Promise<any>}
	 * @memberof HomeTraining
	 */
	public static async getInitialProps(context: any): Promise<any> {
		const { query, params } = context.req;
		// 注册接口
		// serviceCommunity.register(context);

		let initData: any = {
			...params,
		};
		const res:any = await	post({ url:'/article/listArticleForOffice', data: { page: 1, size: 5 }}, context.req)
		initData.communityList = res.data
	
		return {
			query,
			initData,
			sysTime: Date.now(),
		};
	}

	/**
	 * 客户端调用获取数据
	 *
	 * @memberof HomeTraining
	 */
	public async componentDidMount(): Promise<any> {
		// 注册接口
		// serviceCommunity.register(this.props);
		this.initWowScript();
		const tab: any = sessionStorage.getItem('edu-home-active-tab');
		if (tab) {
			this.setState({
				activeTab: tab,
			});
		}

		return {};
	}

	/**
	 * 初始化
	 */
	public async initWowScript(): Promise<any> {
		await this.createScript('https://media.qlchat.com/qlLive/activity/file/QBKBO41J-RLCI-MOVV-1630580445604-X24XZHO7JSF3.js');
		if (!/msie [6|7|8|9]/i.test(navigator.userAgent)) {
			return await new (window as any).WOW().init();
		}
	}

	/**
	 * 创建
	 */
	public createScript(src: string): any {
		return new Promise((resolve, reject): any => {
			const script: any = document.createElement('script');
			script.src = src;
			script.onload = (): any => {
				resolve(true);
			};
			document.body.appendChild(script);
		});
	}

	/**
	 * 回到顶部
	 */
	public toTopFunc(): any {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	/**
	 * @description 了解千聊点击
	 */
	public setQlIntroIndex = (index: number): void => {
		this.setState({
			qlIntroIndex: index,
		});
	}

	/**
	 * 渲染页面
	 *
	 * @returns {*}
	 * @memberof HomeTraining
	 */
	public render(): any {
		const { htmlTDK, qlIntroIndex, host } = this.state;
		const { initData } = this.props;

		return (
			<Layout {...getHtmlTDK(htmlTDK)} bodyClass="home-training-page" resetClass="home-training-parent-box" region="create-live_pc_homepage_top_tab">
				{/* 顶部banner */}
				<HomeTrainingTopBanner />
				<div className="main-container">
					{/* 品牌信息 */}
					<div className="main-container-brand">
						<div className="home-training-title wow fadeInUp" id="topic">
							<img className='home-training-title-img' src="https://media.qianliaowang.com/qlLive/activity/image/9TRFCTQL-A3UK-9PV5-1724393451855-QAJRMP8DLZ4D.png?x-oss-process=image/format,webp" />
							<div className='home-training-title-desc'>
								<div>{ishaizhan() ? '广州知识天下信息科技有限公司——' : ''}兴趣岛是一家成立于2016年的文化服务科技公司，为用户提供多种兴趣学习产品。</div>
								<div>截至目前，兴趣岛App注册用户3500万+。艾媒认证，兴趣岛摄影、瑜伽、太极、养生等30门主流课程内的21门课程学员数量全国领先。</div>
							</div>
						</div>
						<BrandInfo classNames="main-container-width wow fadeInUp"/>
					</div>
					{/* 荣誉信息 */}
					<div className="main-container-honorary">
						<div className="home-training-title wow fadeInUp" id="topic">
							<img className='home-training-title-img' src="https://img.qlchat.com/qlLive/activity/image/2JCMCSE6-YF3X-TO78-1708932613441-6Q24ZAMPW89L.png?x-oss-process=image/format,webp" />
							<div className='home-training-title-desc'>
								<div>2016年至今，兴趣岛始终坚持用户第一，获得千万用户的肯定与好评，在课程学员数量上领先行业。</div>
								<div>兴趣岛引领行业发展，受到中国家庭教育学会、中国营养协会等权威单位认可，并屡获腾讯、百度、360等平台奖项。</div>
							</div>
						</div>
						<HonoraryPan classNames="main-container-width wow fadeInUp" />
					</div>
					{/* 品牌报道 */}
					<div className="main-container-brand-report">
						<div className="home-training-title wow fadeInUp" id="topic">
							<img className='home-training-title-img' src="https://img.qlchat.com/qlLive/activity/image/JW8NSD76-3C23-2JLI-1708942309158-ZDYJEDUL1CW9.png?x-oss-process=image/format,webp" />
						</div>
						<BrandReport classNames="wow fadeInUp" />
					</div>
				</div>

				<div id="contact"></div>
				<FixedRightTools/>
			</Layout>
		);
	}
}

/**
 * mapStateToProps
 */
function mapStateToProps(state: any): any {
	return {
		// categoryList: state.common.categoryList,
	};
}

const mapActionToProps: any = {};

export default connect(mapStateToProps, mapActionToProps)(HomeTraining);
